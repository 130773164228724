import { QrcodeResult } from "html5-qrcode/esm/core";
import React, { useState } from "react";
import QRCodeScanner from "./QRCodeScanner";

const App: React.FC = () => {
  const [isOpenQR, setIsOpenQR] = useState<boolean>(false);
  const [qrData, setQrData] = useState("");
  const handleQrCodeSuccess = (decodedText: string, result: QrcodeResult) => {
    console.log("QR Code Decoded:", decodedText);
    setQrData(decodedText);
    setIsOpenQR(false);
  };

  const handleQrCodeError = (error: Error) => {
    console.error("QR Code Error:", error.message);
    // Add your error handling logic here
  };

  return (
    <div>
      <h1>HTML5 QR Code Scanner</h1>
      {isOpenQR ? (
        <QRCodeScanner
          onSuccess={handleQrCodeSuccess}
          onError={handleQrCodeError}
        />
      ) : (
        <></>
      )}

      <div className="qr-button-container">
      {isOpenQR ? (
        <button className="qr-button" onClick={() => setIsOpenQR(false)}>
          Close qr scanner
        </button>
      ) : (
        <button className="qr-button" onClick={() => setIsOpenQR(true)}>
          Open qr scanner
        </button>
      )}
      </div>
      <label style={{ color: "white" }}>{qrData}</label>
    </div>
  );
};

export default App;
