import {
  Html5QrcodeScanner,
  Html5QrcodeSupportedFormats,
  Html5Qrcode,
} from "html5-qrcode";
import { useEffect, useState } from "react";
import "./qrcode.css";

interface QRCodeScannerProps {
  onSuccess: (qrText: string, decodedResult: any) => void;
  onError: (error: Error) => void;
}

const QRCodeScanner = ({ onSuccess, onError }: QRCodeScannerProps) => {
  const [qrResult, setQrResult] = useState<string>("");

  const clickButtonById = (id: string) => {
    const buttonElement = document.getElementById(id);
    if (buttonElement) {
      buttonElement.click();
    } else {
      console.error(`Button with ID ${id} not found`);
    }
  };

  useEffect(() => {
    // Continue with the QR code scanning logic here
    const formatsToSupport = [
      Html5QrcodeSupportedFormats.QR_CODE,
      Html5QrcodeSupportedFormats.UPC_A,
      Html5QrcodeSupportedFormats.UPC_E,
      Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
    ];

    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        formatsToSupport,
        videoConstraints: {
          facingMode: { exact: "environment" },
        },
        // showZoomSliderIfSupported: true,
        // showTorchButtonIfSupported: true,
      },
      /* verbose= */ false
    );

    const onScanSuccess = (decodedText: string, decodedResult: any) => {
      // Handle the scanned code as you like, for example:
      setQrResult(decodedText);
      onSuccess(decodedText, decodedResult);
      html5QrcodeScanner.clear(); //-- auto close?
      console.log(`Code matched = ${decodedText}`, decodedResult);
    };
    const onScanError = (err: any) => {
      console.warn(err);
    };
    html5QrcodeScanner.render(onScanSuccess, onScanError);

    let buttonId = "html5-qrcode-button-camera-permission";
    clickButtonById(buttonId);

    buttonId = "html5-qrcode-button-camera-start";
    const intervalId = setInterval(() => {
      const buttonElement = document.getElementById(buttonId);
      if (buttonElement) {
        clickButtonById(buttonId);
        clearInterval(intervalId); // Stop the interval once the button is found and clicked
      }
    }, 1000); // Check every second (adjust the interval as needed)

    return () => {
      html5QrcodeScanner.clear(); // Cleanup the scanner when component unmounts
    };
  }, []);

  return (
    <>
      {/* <button
        id="html5-qrcode-button-camera-permission"
        className="html5-qrcode-element"
        type="button"
      >
        Request Camera Permissions OK
      </button>
      <button
        id="html5-qrcode-button-camera-start"
        className="html5-qrcode-element"
        type="button"
        style={{ opacity: "1", display: "inline-block" }}
      >
        Start Scanning OK
      </button> */}
      {qrResult ? <div> qrResult: {qrResult}</div> : <div id="reader"></div>}
    </>
  );
};

export default QRCodeScanner;
